<template>
  
  <v-overlay :value="status">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>

</template>

<script>

  export default {
    name: 'Loading',

    components: {
    },

    props: {
      status: {
        type: Boolean
      }
    },

    data: () => ({
    }),

    mounted(){},

    methods: {}

  }
</script>
