<template>
  <v-alert text :color="color" @click="onClick">
    <h3 class="headline">
      {{ title }}
    </h3>
    <slot></slot>
  </v-alert>
</template>

<script>
  export default {
    name: 'Message',

    components: {},

    props: {
      color: {
        type: String,
        default: 'info'
      },
      title: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      
    }),

    mounted(){},

    methods: {
      onClick()
      {
        this.$emit('click')
      }
    }

  }
</script>
