<template>
  <v-snackbar v-model="growler.status" :color="growler.color">
    {{ growler.text }}
  </v-snackbar>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Growler',

    components: {},

    props: {},

    data: () => ({

    }),

    computed: {
      ...mapGetters([
        'growler'
      ])
    },

    mounted(){},

    methods: {
      
    }

  }
</script>
