<template>
  
  <div style="display:inline-block">

    <v-btn @click="show = !show" v-if="!show">
      <v-icon class="mr-2">mdi-eye</v-icon>
      OCR
    </v-btn>

    <div v-else class="mt-5">

      <div v-if="!image">
        
        <v-select
          :items="cameras" 
          v-model="camera"
          v-if="cameras.length > 1" 
          item-text="label"
          item-value="deviceId" 
          label="Change camera" 
          prepend-icon="mdi-video"
          class="mt-5"
          solo
        ></v-select>

        <div id="webcam-camera">
          <web-cam ref="video" 
                  :selectFirstDevice="true"
                  @cameras="onCamerasList"></web-cam>
        </div>

        <div>
          
          <v-btn @click="show = !show" 
                 class="mr-2 mb-2"
                 :block="isMobile">
            <v-icon class="mr-2">mdi-eye-off</v-icon>
            Close OCR
          </v-btn>
          
          <v-btn @click="capture" 
                 color="primary" 
                 class="mb-2"
                 :block="isMobile">
            <v-icon class="mr-2">mdi-camera</v-icon>
            Get picture
          </v-btn>

        </div>

      </div>

      <div v-else>

        <vue-cropper
          ref="cropper"
          :src="image"
          alt="Source Image"
        >
        </vue-cropper>

        <div class="mt-5">

          <v-btn @click="discard" 
                 class="mr-2 mb-2"
                 :block="isMobile">
            <v-icon class="mr-2">mdi-delete</v-icon>
            Discard
          </v-btn>

          <v-btn @click="cropAndRead" 
                 color="primary" 
                 class="mr-2 mb-2"
                 :block="isMobile">
            <v-icon class="mr-2">mdi-format-font</v-icon>
            Read text
          </v-btn>

          <v-btn @click="crop" 
                 color="primary" 
                 class="mb-2"
                 :block="isMobile">
            <v-icon class="mr-2">mdi-image</v-icon>
            Save as image
          </v-btn>

        </div>
        
      </div>
      
      <canvas ref="canvas" id="canvas" width="640" height="480"></canvas>

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular> 
        <p class="display-1 mt-5">{{ progress }}%</p>
      </v-overlay>
      
    </div>
    
  </div>

</template>

<style type="text/css" scoped>
  textarea { 
    max-height: 200px; 
    overflow-y: auto !important;
  }
  #webcam-camera {
    position: relative;
  }
  #webcam-camera::before {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 5px solid rgba(0,0,0,.2);
    top: 100px;
    left: 0;
  }
</style>

<script>

  import _ from 'lodash'
  import GCloud from '../gcloud'
  import Tesseract from 'tesseract.js'
  import Utils from '../utils'
  import VueCropper from 'vue-cropperjs'
  import 'cropperjs/dist/cropper.css'
  import {WebCam} from "vue-web-cam"

  export default {
    name: 'OCR',

    components: {
      VueCropper,
      WebCam
    },

    mixins: [
      Utils
    ],

    props: {},

    data: () => ({
      camera: null,
      cameras: [],
      image: null,
      overlay: false,
      progress: 0,
      show: false,
      stream: null
    }),

    mounted(){},

    methods: {

      crop()
      {
        var cropped = this.$refs.cropper.getCroppedCanvas().toDataURL()
        
        this.$emit('snapshot', cropped )
          
        this.image = null
      },

      cropAndRead()
      {
        var cropped = this.$refs.cropper.getCroppedCanvas().toDataURL()
        this.ocr(cropped)
      },
      
      capture()
      {
        this.image = this.$refs.video.capture()
      },

      discard()
      {
        this.image = null
      },

      ocr(crop)
      {
        this.overlay = true
        
        this.ocrGoogle(crop)

      },

      ocrGoogle(imageBase64)
      {
        GCloud.ocr( imageBase64.replace('data:image/png;base64,','') )
              .then((res)=>{
                
                let data = res.data.responses,
                    texts = _.map(data, (row)=>{ return row.fullTextAnnotation.text }),
                    // merge all paragraphs and strip line breaks
                    text = texts.join(' ').replace(/(?:\r\n|\r|\n)/g,' ')
                                          .replace(/(\S)(-)( )/g, '$1')

                this.$emit('ocr', text )
          
                this.image = null
                
                this.overlay = false
                
              })
      },

      ocrTesseract(imageBase64)
      {
        Tesseract.recognize(
          imageBase64,
          'ita',
          { logger: m => {
            this.progress = Math.round( m.progress * 100 * 10) / 10
          } }
        ).then(({ data: { text } }) => {

          this.$emit('ocr', text)
          
          this.image = null
          
          this.overlay = false

        })
      },

      onCamerasList(cameras)
      {
        this.cameras = cameras

        // seleziono in automatico la fotocamera posteriore
        var backCamera = _.find(cameras, (c)=>{ 
          var label = c.label.toLowerCase()
          return (
            label.indexOf('posteriore') >= 0
            || label.indexOf('back') >= 0 
          )
        })

        if( backCamera )
          this.$nextTick(() => this.$set(this, 'camera', backCamera.deviceId))
      },

      startVideo()
      {
        if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
         navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
          this.stream = stream
          this.$refs.video.srcObject = stream
          this.$refs.video.play()
         })
        }
      },

      stopVideo()
      {
        if( _.isUndefined(this.$refs.video) )
          return;

        const mediaStream = this.$refs.video.srcObject,
              tracks = mediaStream.getTracks()
              tracks.forEach(track => track.stop())
      }

    },

    watch: {

      camera(cameraId)
      {
        if(cameraId)
          this.$refs.video.changeCamera(cameraId)
      },

      show(isShow)
      {
        if(!isShow)
          this.camera = null
      }

    }

  }
</script>

<style>
  #video {
      background-color: #000000;
  }
  #canvas {
      display: none;
  }
</style>