<template>
	<v-card tile :color="color" elevation="0">
		<slot name="sup"></slot>
		<v-card-title class="text-h4">
			<slot name="prepend"></slot>
			{{ title }}
			<slot></slot>
		</v-card-title>
		<v-card-subtitle>
			{{ subtitle }}
			<slot name="subtitle"></slot>
		</v-card-subtitle>
	</v-card>
</template>

<style type="text/css">
	.v-card { min-height: 86px; }
	.v-card__title { white-space: break-spaces; }
</style>

<script>

	export default {
		
		name: 'Header',

		components: {},

		props: {
			color: {
				type: String,
				default: () => 'grey lighten-2'
			},
			title: {
				type: String
			},
			subtitle: {
				type: String
			}
		},

		data: () => ({
		}),

		mounted(){},

		methods: {}

	}
</script>
