<template>
  <img class="logo" 
       :src="src"
       @click="onClick"/>
</template>

<style type="text/css">
  
  .logo {
    max-width: 100%;
    max-height: 30px;
  }

  @media (max-width: 767px) {
    .logo {
      max-height: 20px;
    }
  }

</style>

<script>

  export default {
    
    name: 'Logo',

    components: {},

    props: {
      white: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
    }),

    computed: {
      src()
      {
        let white = this.white ? '-white' : ''

        return `/img/logo${white}.svg`
      }
    },

    mounted(){},

    methods: {
      onClick()
      {
        this.$emit('click')
      }
    }

  }
</script>
